@import "../../Global.scss";
.refunds{
    width: 100%;
    height: calc(100vh - 70px);
    background-color: beige;
    
    margin-top: 70px;

    .content{
        height: calc(100% - 75px);
        align-items: center;
        display: flex;
        justify-content: space-around;
        .left{
            // border: 1px black solid;
            flex: 1;
            img{
                width: 70%;
                border-radius: 15px;
                margin-left: 15%;
            }

            @include lmobile{
                display: none;
            }
        }
        .right{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            flex: 1;
            @include mobile{
                flex: 2;
                padding: 15px;
            }

            p{
                text-align: justify;
            }
            
            h1{
                text-align: center;
                font-size: 40px;
                height: 18%;

                @include mobile{
                    font-size: 30px;
                    height: 15%;
                }
            }
            ul{
                text-align: justify;
                padding: 15px;

                
            }
        }
    }

    

    .buttons{
        margin-bottom: 30px;
        display: flex;
        justify-content: space-around;
        

        a{
            text-decoration: none;
            box-sizing: border-box;
            text-align: center;
            padding: 8px 0;
            color: beige;
            font-weight: bold;
            font-size: 0.8em;
            width: 20%;
            height: 35px;
            border-radius: 10px;
            background-color: $mainColor;
            border: none;
            cursor: pointer;

            @include mobile{
                width: 30%;
                font-size: 0.6;
            }
            @include lmobile{
                width: 30%;
                font-size: 0.7em;
            }
        }
    }
}