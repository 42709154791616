@import "../../../Global.scss";

li {
    font-size: 16px;
    font-weight: bold;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;

    @include mobile{
        margin-right: 20px;
        font-weight: bold;
      }
    @include lmobile{
        font-size: 12px;
        font-weight: bold;
        margin-right: 10px;
    }
    @include mmobile{
        font-size: 12px;
        font-weight: bold;
        margin-right: 15px;
        padding: 5px;
        border-radius: 5px;
    }
    @include smobile{
        font-size: 11px;
        font-weight: bold;
        padding: 5px;
        // margin-right: 5px;
    }
    &.active {
        background-color: $mainColor;
        color: beige;

    }
}