@import "../../Global.scss";

.topbar {
    width: 100%;
    height: 70px;
    background-color: beige;
    color: $mainColor;
    position: fixed;
    top: 0;
    z-index: 3;
    overflow-y: hidden;
    transition: all 1s ease;

    .wrapper {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            
            display: flex;
            align-items: center;

            .logo {
                display: flex;
                font-size: 28px;
                font-weight: 700;
                font-family: 'Alkatra', cursive;
                text-decoration: none;
                color: inherit;
                margin-right: 5px;
                // .ac{
                //     margin-left: 5px;
                // }
                @include mobile{
                    font-size: 25px;
                }

                @include mmobile{
                    font-size: 18px;
                }


            }

            .itemcontainer {
                display: flex;
                align-items: center;
                margin-left: 30px;

                @include mobile{
                    display: none;
                }

                .icon {
                    font-size: 20px;
                    margin-right: 5px;
                }

                span {
                    font-size: 18px;
                    font-weight: 700;

                    ul {
                       
                        overflow: hidden;
                        list-style: none;
                        font-size: inherit;
                        font-weight: inherit;
                        color: beige;

                        li {
                            display: inline;
                            list-style: none;

                            a {
                                color: $mainColor;
                                font-size: inherit;
                                text-decoration: none;
                            }

                            &:hover {
                                font-weight: 900;
                            }
                        }

                    }
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            .itemcontainer {
                display: flex;
                align-items: center;
                margin-left: 30px;

                @include topwidth{
                    display: none;
                }

                .navList {
                    font-size: 15px;
                    font-weight: 700;

                    ul {
                       
                        overflow: hidden;
                        list-style: none;
                        font-size: inherit;
                        font-weight: inherit;
                        color: beige;

                        li {
                            display: inline;
                            list-style: none;

                            a {
                                color: $mainColor;
                                font-size: inherit;
                                text-decoration: none;
                            }

                            &:hover {
                                font-weight: 900;
                            }
                        }

                    }
                }
            }
            .hamburger {
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
            }

            span {
                width: 100%;
                height: 3px;
                background-color: $mainColor;
                transform-origin: left;
                transition: all 1s ease;
            }
        }
    }

    &.active {
        background-color: $mainColor;
        color: beige;

        .hamburger {
            span {
                &:first-child {
                    background-color: beige;
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:last-child {
                    background-color: beige;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}