@import "../../global.scss";
.aboutus{

    h1{
        text-align: center;
        font-size: 40px;
        // height: 20%;
        // padding-top: 45px;
        box-sizing: border-box;
        @include lmobile{
            font-size: 30px;
        }
    }
    img{
        margin: 3% 30%;
        width:40%;
        border-radius: 15px;
        @include lmobile{
            margin: 7% 25%;
            width: 50%;
        }

    }
    .info{
        padding: 0 5%;
        text-align: center;
        color: rgb(83, 83, 83);
        font-size: 20px;
        overflow: hidden;

        @include lmobile{
            font-size: 15px;
        }
    }
}