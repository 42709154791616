@import "../../Global.scss";

.privacy{
    width: 100%;
    height: calc(100vh - 70px);
    background-color: beige;

    margin-top: 70px;

    h1{
        text-align: center;
        font-size: 40px;
    }

    ol{
        list-style-type: upper-roman;
        padding: 35px;
        text-align: justify;
        li{
            h3{
                font-weight: bold;
                font-size: 25px;
                text-decoration: underline;
                margin-bottom: 5px;
            }
            p{
                padding-left: 20px;
            }
        }
    }

    .buttons{
        margin-bottom: 30px;
        display: flex;
        justify-content: space-around;
        

        a{
            text-decoration: none;
            box-sizing: border-box;
            text-align: center;
            padding: 8px 0;
            color: beige;
            font-weight: bold;
            font-size: 0.8em;
            width: 20%;
            height: 35px;
            border-radius: 10px;
            background-color: $mainColor;
            border: none;
            cursor: pointer;

            @include mobile{
                width: 30%;
                font-size: 0.6;
            }
            @include lmobile{
                width: 30%;
                font-size: 0.7em;
            }
        }
    }
}