@import "../../global.scss";
$w : 150px;

.whyus{
    background-color: beige;
    height: calc(100vh - 70px);
    // border: 1px black solid;
    box-sizing: border-box;
   
    .head{
        // border: 1px black solid;
        text-align: center;
        font-size: 55px;
        height: fit-content;
        padding-top: 45px;
        box-sizing: border-box;

        @include mobile{
            font-size: 45px;
            padding-top: 30px;
        }

    }
    .content{
        // border: 1px black solid;
        display: flex;
        justify-content: space-around;
        
        align-items: center;
        width: 100%;
        height: 80%;
        
        @include lmobile{
            flex-direction: column;
        }

        // padding: 10% 0;
        .box{
            box-sizing: border-box;
            // border: 1px black solid;
            width: 24%;
            height: 280px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            // margin-top: 5px;
            
            @include lmobile{
                flex-direction: row;
                width: 80%;
                height: 24%;
                margin: 5px;
                justify-content: space-between;
                overflow: hidden;
            }


            img{
                width: 60%;
                @include lmobile{
                    width: 20%;
                }
            }
            h3{
                text-align: center;
                font-size: 20px;
                padding: 10% 10%;
                @include mobile{
                    font-size: 16px;
                    padding: 10% 5%;
                    overflow: hidden;
                    
                }
                @include lmobile{
                    width: 65%;
                    box-sizing: border-box;
                    font-size: 16px;
                    padding: 10% 5%;
                    padding-right: 0;
                    overflow: hidden;
                }

                
            }
        }
    }
}