@import "../../global.scss";
.testimonial{
    background-color: beige;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px black solid;

    @include mobile{
        justify-content: space-around;
      }

    h1{
        font-size: 50px;

        @include mobile{
            font-size: 30px;
            margin: 5px;
            overflow: hidden;
        }
    }

    .container{
        // border: 1px red solid;
        width: 100%;
        height: 85%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile{
            flex-direction: column;
            height: 100%;
          }

        .card{
            width: 250px;
            height: 70%;
            border-radius: 10px;
            box-shadow: 0px 0px 15px -8px black;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            transition: all 1s ease;
            margin: 0 30px;

            @include mobile{
                height: 200px;
                margin: 5px 0;
                padding: 2px;
              }

            &.featured{
                width: 300px;
                height: 75%;
                margin: 0 30px;

                @include mobile{
                    width: 250px;
                    height: 180px;
                    margin: 1px;
                  }
            }

            &:hover{
                transform: scale(1.1);
            }

            .top{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 5px 0 0 0;

                img{
                    &.left,&.right{
                        height: 30px;
                        @include mobile{
                            height: 25px;
                        }
                    }
                    &.user{
                        height: 80px;
                        width: 80px;
                        object-fit: cover;
                        margin: 0 30px;
                        border-radius: 50%;

                        @include mobile{
                            width: 20px;
                            height: 20px;
                          }
                    }
                }
            }

            .center{
                text-align: center;
                padding: 10px;
                border-radius: 10px;
                background-color: rgb(250, 240, 245);

                @include mobile{
                    font-size: 10px;
                    padding: 5px;
                    overflow: hidden;
                  }
            }

            .bottom{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h3{
                    margin-bottom: 5px;

                    @include mobile{
                        font-size: 12px;
                      }
                }
                h4{
                    color: grey;

                    @include mobile{
                        font-size: 10px;
                      }
                }
            }
        }
    }
}