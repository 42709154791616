@import "../../Global.scss";

.portfolio {
    background-color: beige;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mmobile{
        margin-top: 5px;
    }

    h1 {
        font-size: 50px;
        overflow: hidden;

        @include midwidth {
            font-size: 45px;
            overflow: hidden;
            margin: 5px;
            height: 20%;
        }
        @include mobile{
            height: 15%;
        }

        @include lmobile{
            font-size: 40px;
            overflow: hidden;
            margin: 0px;
            height: 45px;
            padding-bottom: 10px;
        }
        @include mmobile{
            font-size: 40px;
            overflow: hidden;
            font-size: 25px;
            margin: 0px;
            height: 45px;
            // padding-top: 15px;
        }
        @include smobile {
            font-size: 20px;
            height: 45px;
            overflow: hidden;
        }

    }

    .portfoliolist{
        overflow: hidden;
        @include lmobile{
            margin: 10px 0;
            padding-bottom: 5px;
            overflow: hidden;
        }
        @include mmobile{
            margin-top: 5px;
            overflow: hidden;
        }
       
        ul {
            margin: 25px;
            padding: 0;
            list-style: none;
            display: flex;
            // border: 1px black solid;
    
            @include mobile {
                margin: 10px 0;
                flex-wrap: wrap;
                justify-content: center;
                // min-height: 65px;
                overflow: hidden;
                padding: 10px 0;
    
            }
            @include smobile{
                // margin-top: 5px;
                height: 35px;
                z-index: 0;
                position: relative;
                top: -20px;
                left: 5px;
                // left: 10px;
                overflow: hidden;
                // padding: 10px 0;
            }
            
    
    
        }
    }

    .container {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        

        @include mobile {
            width: 100%;
            margin: 10px;
        }

        .itembox {
            display: flex;

            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px 20px;
            border-radius: 10px;
            // border: 1px rgb(75, 75, 75) solid;

            h3 {
                text-align: center;
                font-size: 14px;
                width: 60%;
                margin: 5px 0;

            }

            .item {
                // box-shadow: 0 0px 5px 0px rgba(25, 25, 25, 0.5);
                width: 220px;
                height: 150px;

                display: flex;
                align-items: center;
                justify-content: center;
                color: beige;
                position: relative;
                // transition: all 0.5s ease;
                cursor: pointer;

                @include mobile {
                    width: 130px;
                    height: 100px;
                }

                h3 {
                    position: absolute;
                    font-size: 20px;

                    @include mobile {
                        font-size: 20px;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: 1;
                }

                &:hover {
                    background-color: $mainColor;

                    img {
                        opacity: 0.2;
                        z-index: 0;
                    }
                }

            }

            .title {
                width: 100%;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top: 1px rgba(63, 62, 62, 0.3) solid;
                box-shadow: 0 2px 5px 0px rgba(53, 52, 52, 0.3);

                @include mobile {
                    font-size: 12px;
                }
            }
        }

    }

    .popUpBox {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5% 10%;
        z-index: 1;
        // border-radius: 20px;
        @include lmobile{
            padding: 10%;
        }
        
        .popUpHeader {
            button{
                color: red;
                border-radius: 30%;
                font-size: 1em;
                width: 30px;
                height: 30px;
                @include laptop_l{
                    width: 35px;
                height: 35px;
                }
                @include mobile{
                    width: 30px;
                height: 30px;
                }
            }

            text-align: right;

            @include mobile{
                position: relative;
                top: 35px;
                left: 10px;
            }
            @include lmobile{
                position: relative;
                top: 35px;
                left: 20px;
            }
            
            @include laptop_l{
                position: relative;
                top: 40px;
                left: 20px;
            }
        }

        .popUpBody {
            //  border: 1px black solid;
            padding: 2%;
            width: 90%;
            height: 100%;
            margin: auto;
            border-radius: 20px;
            background-color: white;
            overflow: hidden;

            @include mobile{
                padding: 1%;
            width: 90%;
            height: 90%;
            margin-top: 3%;
            }
            @include mmobile{
                margin-top: 8%;
            }
            @include mmobile{
                margin-top: 10%;
            }
            
            .popUpContent{
                width: 100%;
                height: 100%;
                // border: 1px rgb(255, 0, 0) solid;
                overflow: hidden;
                .container{
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    align-items: flex-start;
                    
                   

                    .banner{
                        width: 80%;
                        // border: 3px black solid;
                        height: 40%;
                        overflow: hidden;
                        border-radius: 20px;
                        .banner-s{
                            display: none;
                         }
                        @include lmobile{
                            // padding-right: 5%;
                            border-radius: 10px;
                        }
                        @include mmobile{
                            padding-right: 8%;
                         }
                         
                        img{
                            margin-right: 5%;
                            width: 100%;
                            height: 100%;
                            // margin: 0 auto;
                        }

                        @include sban{
                            .banner-s{
                                display: block;
                             }
                             .banner-l{
                                display: none;
                             }
                         }

                    }

                    .description{
                        box-sizing: border-box;
                        padding: 3% 5%;
                        height: 30%;
                        text-align: center;
                        font-size: 1.5em;
                        overflow: hidden;

                        @include mobile{
                            font-size: 1em;
                        }
                        @include smobile{
                            font-size: 0.8em;
                        }

                        p{
                            // margin: auto;
                            overflow: hidden;
                            @include lmobile{
                                padding-right: 5%;
                                border-radius: 2px;
                            }
                        }
                    }

                    .button {
                        width: 50%;
                        background-color: crimson;
                        border-radius: 10px;
                        color: beige;
                        padding: 10px 20px;
                        text-align: center;
                        text-decoration: none;
                        // display: inline-block;
                        font-size: 25px;
                        // margin: auto;
                        cursor: pointer;
                        transition: all 1s ease;

                        @include mobile{
                            font-size: 20px;
                            width: 60%;
                        }
                        @include lmobile{
                            margin-right: 5%;
                        }
        
                        &:hover{
                            transform: scale(1.1);
                            background-color: #25D366;
                        }
                    }

                }
            }
            
        }
    }

}