@import "../../Global.scss";
.termsandconditions{
    width: 100%;
    height: calc(100vh - 70px);
    background-color: beige;
    
    margin-top: 70px;
    
    background-color: #d5effe;
    

    h1{
        text-align: center;
        font-size: 40px;
    }

    .content{
        p{
            text-align: justify;
            padding: 35px;
        }
        img{
            border-radius: 50%;
            width:15%;
            margin: 1% 40%;
        }
    }

    .buttons{
        margin-bottom: 30px;
        display: flex;
        justify-content: space-around;
        

        a{
            text-decoration: none;
            box-sizing: border-box;
            text-align: center;
            padding: 8px 0;
            color: beige;
            font-weight: bold;
            font-size: 0.9em;
            width: 25%;
            height: 35px;
            border-radius: 10px;
            background-color: $mainColor;
            border: none;
            cursor: pointer;

            @include mobile{
                width: 25%;
                font-size: 0.8;
            }
            @include lmobile{
                width: 30%;
                font-size: 0.7em;
            }
        }
    }
    

}