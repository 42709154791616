@import "../../Global.scss";

.intro {
    background-color: beige;
    display: flex;

    @include midwidth{
        flex-direction: column;
        align-items: center;
    }


    .left {
        flex: 0.5;
        overflow: hidden;

        // justify-content: left;
        .imgcontainer {
            width: 800px;
            height: 800px;
            background-color: crimson;
            border-radius: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;

            @include midwidth{
                align-items: flex-start;
            }

            img {
                height: 90%;
                @include midwidth{
                    height: 50%;
                }
            }
        }

    }

    .right {
        flex: 0.5;
        background-color: beige;
        position: relative;

        .cert{
            box-sizing: border-box;
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: space-around;
            padding-right: 10%;
        }

        .wrapper {
            width: 100%;
            height: 80%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            @include midwidth{
                padding-left: 0%;
                align-items: center;
            }

            h1 {
                font-family: 'Alkatra', cursive;
                font-size: 60px;
                padding: 10px 0;

                @include midwidth{
                    font-size: 40px;
                    overflow: hidden;
                }

                @include lmobile{
                    font-size: 30px;

                }
            }

            h2 {
                font-size: 35px;
               
                @include mobile{
                    display: none;
                }
                @include midwidth{
                    font-size: 20px;
                    overflow: hidden;
                }
            }

            h3 {
              
                font-size: 30px;
                @include midwidth{
                    font-size: 20px;
                   overflow: hidden;   
                }
                span{
                    font-size: inherit;
                    color: crimson;
                }
            }

            .button {
                width: 50%;
                background-color: crimson;
                border-radius: 10px;
                color: beige;
                padding: 10px 20px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 25px;
                margin: 8% 0 5%;
                cursor: pointer;
                transition: all 1s ease;
                @include midwidth{
                    font-size: 18px;
                   overflow: hidden;   
                }

                &:hover{
                    transform: scale(1.1);
                    background-color: #25D366;
                }
            }

        }
        .downArrow{
            position: absolute;
            bottom: 10px;
            // left: 40%;

            img{
                width: 30px;
                animation: arrowBlink 3s infinite;
            }

            @keyframes arrowBlink {
                50%{
                    opacity: 0;
                }
            }
        }
    }
}