@import "../../global.scss";

.contact{
    background-color: beige;
  display: flex;

  @include lmobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    overflow: hidden;

    @include mobile{
      display: none;
    }

    .itemcontainer {
      display: flex;
      align-items: center;
      margin-left: 30px;

      .icon {
        font-size: 18px;
        margin-right: 5px;
    }
    span {
      font-size: 15px;
      font-weight: 700;
      margin-right: 20px;
      .phone,.mail{
        text-decoration: none;
        color: black;
      }
    }
  }

    img {
      height: 100%;
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box{
      display: flex;
      width: 65%;
      @include mobile{
        width: 70%;
      }
      .rleft{
        // border: 1px black solid;
        flex: 1;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 40px;
          height: 40px;
          @include mobile{
            width: 30px;
            height: 30px;
          }
        }
      }
      .right{
        margin: 4%;
        flex: 3;
        .top{
          margin-bottom: 2%;
          font-size: 20px;
          font-weight: bold;
        }
        .bottom{
          text-align: center;
          color: rgb(85,85,85);
          width: 80%;
          @include mobile{
            width: 80%;
          }
        }
      }
    }
}
}