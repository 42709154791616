$mainColor : #15023a;
$mainBgColor : #f5f5dc;

$width: 768px;
$mobile_sban: 450px;
$mobile_l: 425px;
$mobile_m: 375px;
$mobile_s: 320px;
$mid_width: 980px;
$top_width: 1110px;
$laptop_l: 1200px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
}

@mixin lmobile {
    @media (max-width: #{$mobile_l}){
        @content
    }
}

@mixin mmobile {
    @media (max-width: #{$mobile_m}){
        @content
    }
}

@mixin smobile {
    @media (max-width: #{$mobile_s}){
        @content
    }
}


@mixin midwidth {
    @media (max-width: #{$mid_width}){
        @content
    }
}

@mixin sban {
    @media (max-width: #{$mobile_sban}){
        @content
    }
}

@mixin topwidth {
    @media (max-width: #{$top_width}){
        @content
    }
}
@mixin laptop_l {
    @media (max-width: #{$laptop_l}){
        @content
    }
}