.sections{
    width: 100%;
    height: calc(100vh - 70px);
    background-color: beige;
    // position: relative;
    margin-top: 70px;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;

    > *{
        scroll-snap-align: start;
        width: 100vw;
        height: calc(100vh - 70px);
    }
}