@import "../../Global.scss";

.menu{
    width: 300px;
    height: calc(100vh - 70px);
    margin-top: 70px;
    position: fixed;
    // top: 12px;
    right: -300px;
    background-color: beige;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 1s ease;

    &.active{
        right: 0;
        background-color: $mainColor;
    }
    ul{
        // border: 1px red solid;
        margin-top: 20px;
        padding: 10 0;
        list-style: none;
        font-size: 40px;
        font-weight: normal;
        color: beige;
        
    }

    li{
        border-left: 1px beige solid;
        margin-bottom: 20px;
       
       a{
        color: inherit;
        font-size: inherit;
        text-decoration: none;
       }
       overflow: hidden;
       
       &:hover{
        font-weight: 700;
       }

    }
    .tli{
        border: none;
        .terms{
        
            position: relative;
            top: 8px;
            left: 10px;
            margin: 0;
            padding: 0 20px;
            li{
                
                margin: 3px 0;
                border-left: none;
                a{
                    font-size: 12px;
                }
            }
           }
       }

    

    .itemcontainer{
        height: 25%;
        margin: 0;
        // padding: 3;
        list-style: none;
        font-size: 19px;
        font-weight: normal;
        text-align: center;
        color: beige;
        // border: 1px white solid;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 11%;
    box-sizing: border-box;
        .phone,.mail{
            text-decoration: none;
            color: beige;
          }
          div{
            padding: 3px;
            margin-bottom: 3;
            transition: 1s all ease;
            :hover{
                background-color: rgba(0, 128, 0, 0.498);
                font-weight: 600;
                border-radius: 5px;
            }
          }
    }

}